/* eslint-disable linebreak-style */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'prompts',
  source: [],
};

export default function promptsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_PROMPTS:
      return {
        ...state,
        init: false
      };
    case actions.FETCH_PROMPTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        init: true
      };
   
    default:
      return state;
  }
}
