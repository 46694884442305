import {
  Accounts,
  Question,
  Settings,
  Realtime,
  Vbo,
  Logout,
  Tag,
  Database,
  Tableau,
  RocketLaunch,
  ComputerTower,
} from 'assets/images/icons/sidebar';
import { api } from 'settings';

const getMenu = () => [
  {
    key: 'get-started',
    label: 'sidebar.getStarted',
    wrapperClass: 'fixed-submenu',
    MenuIcon: RocketLaunch,
    roles: ['AccountAdmin'],
  },
  {
    key: 'tableau',
    label: 'sidebar.dashboards',
    externalLink: api.tableau.eaUrl,
    MenuIcon: Tableau,
    type: 'tableau',
  },
  {
    key: 'tableau-embedded',
    label: 'sidebar.dashboards',
    MenuIcon: Tableau,
    type: 'tableau',
  },
  {
    key: 'accounts',
    label: 'sidebar.accounts',
    MenuIcon: Accounts,
    roles: ['SuperAdmin'],
  },
  {
    key: 'vbo',
    label: 'sidebar.vbo',
    roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'TableauAccess'],
    products: ['VBO'],
    MenuIcon: Vbo,
    children: [
      {
        key: 'myReports',
        label: 'sidebar.myReports',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'TableauAccess'],
      },
      {
        key: 'templateLibrary',
        label: 'sidebar.templateLibrary',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'TableauAccess'],
      },
    ],
  },
  // ! Temporarily Removed Realtime feature from EA - https://voicebase.jira.com/browse/EA-4915
  // {
  //   key: 'realtime',
  //   label: 'sidebar.realtime',
  //   roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'AccountAgent'],
  //   products: ['REALTIME'],
  //   MenuIcon: Realtime,
  //   children: [
  //     {
  //       key: 'realtime',
  //       label: 'sidebar.checklists',
  //       roles: ['SuperAdmin', 'AccountAdmin', 'AccountAgent'],
  //       products: ['REALTIME'],
  //     },
  //     {
  //       key: 'status',
  //       label: 'sidebar.status',
  //       roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
  //       products: ['REALTIME'],
  //     },
  //   ],
  // },
  {
    key: 'workbench',
    label: 'sidebar.analystTools',
    MenuIcon: Tag,
    roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'TableauAccess'],
    children: [
      {
        key: 'workbench',
        label: 'sidebar.workbench',
        wrapperClass: 'fixed-submenu',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
      },
      {
        key: 'conversation-search',
        label: 'sidebar.callSearch',
        wrapperClass: 'fixed-submenu',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
        products: ['EA', 'VBO', 'EA_HOSTED']
      },
      {
        key: 'categories',
        label: 'sidebar.categories',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst', 'TableauAccess'],
      },
      {
        key: 'category-library',
        label: 'sidebar.categoryLibrary',
        roles: ['SuperAdmin', 'AccountAdmin'],
      },
      // {
      //   key: 'settings/spotting-groups',
      //   label: 'sidebar.spottingGroups',
      //   roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
      // },
    ],
  },
  {
    key: 'manageData',
    label: 'sidebar.manageData',
    MenuIcon: Database,
    roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
    children: [
      // ! Temporarily Removed Realtime feature from EA - https://voicebase.jira.com/browse/EA-4915, https://voicebase.jira.com/browse/EA-4932
      // {
      //   key: 'settings/phone-keys',
      //   label: 'sidebar.streams',
      //   products: ['REALTIME'],
      // },
      {
        key: 'settings/connections',
        label: 'sidebar.connections',
        roles: ['SuperAdmin', 'AccountAdmin'],
      },
      {
        key: 'settings/jobs-queue',
        label: 'sidebar.jobs',
      },
      {
        key: 'settings/vocabulary-lists',
        label: 'sidebar.vocabularyLists',
      },
      {
        key: 'settings/mappings',
        label: 'sidebar.mappings',
        roles: ['SuperAdmin', 'AccountAdmin'],
      },
      {
        key: 'media-browser',
        label: 'sidebar.communications',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
      },
      {
        key: 'media-download',
        label: 'sidebar.mediaDownload',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
      },
      {
        key: 'prompts',
        label: 'sidebar.promptsSummary',
        roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
      },
      {
        key: 'settings/pci-flag',
        label: 'sidebar.pciFlag',
        roles: ['SuperAdmin', 'AccountAdmin'],
      },
      {
        key: 'hosted-lookup-tables',
        label: 'sidebar.hostedLookupTables',
        roles: ['SuperAdmin'],
        products: ['EA_HOSTED'],
      },
    ],
  },
  {
    key: 'settings',
    label: 'sidebar.settings',
    MenuIcon: Settings,
    roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
  },
  {
    key: 'usage',
    label: 'sidebar.usage',
    MenuIcon: ComputerTower,
    roles: ['SuperAdmin', 'AccountAdmin'],
    bottom: true,
  },
  {
    key: 'documentation',
    label: 'sidebar.documentation',
    MenuIcon: Question,
    roles: ['SuperAdmin', 'AccountAdmin', 'AccountAnalyst'],
    bottom: true,
  },
  {
    key: 'logout',
    label: 'sidebar.logout',
    MenuIcon: Logout,
    bottom: true,
  },
];

export default getMenu;
