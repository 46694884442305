import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import api from 'services';
import platformApi from 'services/platform';
import actions from './actions';
import { transformVocabularyLists, prepVocabularyList } from '../../transform/vocabularyList';

const entityType = 'vocabularyLists';

export function* fetchVocabularyListsSaga() {
  yield takeEvery(actions.FETCH_VOCABULARYLISTS, function* ({url}) {
    const data = yield call(platformApi.platformGetReq, { urlPath: url ? `definition/vocabularies?${url}` : 'definition/vocabularies', disableNotification: true,});
    const transformedData = transformVocabularyLists(data);
    const existingNames = transformedData ? transformedData.map((x) => x.name.toLowerCase()) : [];
    yield put({ type: actions.FETCH_VOCABULARYLISTS_SUCCESS, data: transformedData, existingNames, pagination: data?.pagination });
  });
}

export function* addVocabularyListSaga() {
  yield takeEvery(actions.ADD_VOCABULARYLIST, function* ({ data, id, onFinish }) {
    const vocabularyList = yield call(platformApi.createItem, { entityType, data: prepVocabularyList(data), id: id || data.name });
    if (vocabularyList) {
      yield put({ type: actions.ADD_VOCABULARYLIST_SUCCESS, selectedItem: vocabularyList });
      yield put({ type: actions.FETCH_VOCABULARYLISTS });
    }
    onFinish();
  });
}

export function* editVocabularyListSaga() {
  yield takeEvery(actions.EDIT_VOCABULARYLIST, function* ({ data, id, onFinish }) {
    const vocabularyList = yield call(api.editItem, { entityType, data: prepVocabularyList(data), id });
    yield put({ type: actions.EDIT_VOCABULARYLIST_SUCCESS, selectedItem: vocabularyList });
    yield put({ type: actions.FETCH_VOCABULARYLISTS });
    onFinish();
  });
}

export function* deleteVocabularyListSaga() {
  yield takeEvery(actions.DELETE__VOCABULARYLIST, function* () {
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchVocabularyListsSaga),
    fork(addVocabularyListSaga),
    fork(editVocabularyListSaga),
    fork(deleteVocabularyListSaga),
  ]);
}
