/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import appSaga from './app/saga';
import profileSaga from './profile/saga';
import usersSaga from './users/saga';
import streamsSaga from './streams/saga';
import bearerTokensSaga from './bearerTokens/saga';
import dataSourceSaga from './dataSources/saga';

import accountsSaga from './accounts/saga';
import vocabularyListsSaga from './vocabularyLists/saga';
import checklistsSaga from './checklists/saga';
import checklistItemsSaga from './checklistItems/saga';
import spottingGroupsSaga from './spottingGroups/saga';
import categoriesSaga from './categories/saga';
import collectionsSaga from './collections/saga';
import interactionsSaga from './interactions/saga';
import communicationsSaga from './communications/saga';
import jobsSaga from './jobs/saga';
import tabsSaga from './tabs/saga';
import tabGroupsSaga from './tabGroups/saga';
import connectionsSaga from './connections/saga';
import mappingsSaga from './mappings/saga';
import schemaSaga from './schema/saga';
import playersSaga from './player/saga';
import preferencesSaga from './preferences/saga';
import usageSagas from './usage/sagas';
import statusSagas from './status/sagas';
import agentCallAvoidanceSagas from './agentCallAvoidance/sagas';
import pciFlagSaga from './pciflag/saga';
import vboDashboardSagas from './vboDashboards/saga';
import templateDashboardSagas from './templateDashboards/saga';
import categoryLibrary from './categoryLibrary/saga';
import callSearchSagas from './callSearch/sagas';
import hostedTablesSaga from './hostedTables/saga';
import promptsSaga from './prompts/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    appSaga(),
    profileSaga(),
    collectionsSaga(),
    interactionsSaga(),
    dataSourceSaga(),
    usersSaga(),
    streamsSaga(),
    bearerTokensSaga(),
    accountsSaga(),
    vocabularyListsSaga(),
    communicationsSaga(),
    checklistsSaga(),
    checklistItemsSaga(),
    spottingGroupsSaga(),
    categoriesSaga(),
    jobsSaga(),
    tabsSaga(),
    tabGroupsSaga(),
    connectionsSaga(),
    mappingsSaga(),
    schemaSaga(),
    playersSaga(),
    preferencesSaga(),
    usageSagas(),
    statusSagas(),
    agentCallAvoidanceSagas(),
    pciFlagSaga(),
    vboDashboardSagas(),
    templateDashboardSagas(),
    categoryLibrary(),
    callSearchSagas(),
    hostedTablesSaga(),
    promptsSaga()
  ]);
}
